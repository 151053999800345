<template>
  <div>
    <v-snackbar
      top
      :timeout="attr.timeout"
      :color="attr.color"
      v-model="attr.model"
      ><vue-fontawesome :icon="attr.icon" class="mr-2" />{{ attr.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="attr.model = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    attr: Object
  }
}
</script>
