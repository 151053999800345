import axios from 'axios'
const authorizedApiClient = axios.create({
  baseURL: process.env.VUE_APP_NODE_BASE_URL,
  headers: {
    Accept: 'application/json'
  }
})

// authorizedApiClient.defaults.headers['Authorization'] = 'Bearer ' + token

authorizedApiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    // or get it from localStorage
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
})

export default authorizedApiClient
